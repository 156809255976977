import React from 'react';
import Article, { SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import customevent1 from 'img/help/custom-events/custom-events1.png';
import Link from 'components/ui/link';
import Code from 'components/help/codeSnippet';
import customevent2 from '../../img/help/custom-events/custom-events2.png';
import customevent4 from '../../img/help/custom-events/custom-events4.png';
import { DevelopersLink } from '../../components/Link';
import { Information } from '../../components/information';

const Content = () => {
  return (
    <div>
      <Information color="warning">
        Custom Events are available via JS API.{' '}
        <DevelopersLink blank href="/javascript-api/methods/#track">
          Learn
        </DevelopersLink>{' '}
        how to use it.
      </Information>
      <SubTitle>Custom Event - what is it?</SubTitle>
      <p>
        Custom events allow you to capture more data with every interaction your customers make with
        your website, according to your business needs. You decide how custom events are triggered
        (i.e., clicking a specific button or performing a set of predefined actions). Use Custom
        events as filters to <Link href="/help/how-does-the-search-work/">search</Link> sessions
        that match your research criteria to make it more accurate.
      </p>
      <SubTitle>How to pass Custom Event to LiveSession?</SubTitle>
      <p>
        To pass Custom Events to LiveSession, call the <Code inline>__ls(“track”)</Code> function:
      </p>
      <Code lang="javascript" style={{ marginTop: 32, marginBottom: 24 }}>
        ls(&quot;track&quot;, &quot;User Subscribed&quot;)
      </Code>
      <SubTitle>Where can I find Custom Events?</SubTitle>
      <p>
        Custom Events are available on our filters list. You can find it under toggled search input.
      </p>
      <Image
        src={customevent1}
        lazy
        alt="LiveSession App - Custom Events"
        title="Get to Custom Events"
      />
      <p>
        Choose one or more Custom Events you passed to LiveSession and use them as a search term.
        All of the events you sent will be listed on a filters list. If you don’t see your events,
        please ensure you sent at least one custom event using our{' '}
        <DevelopersLink blank href="/javascript-api/methods/#track">
          API
        </DevelopersLink>
        .
      </p>
      <p>The value of a custom event will be identical to its name:</p>
      <Image
        src={customevent2}
        lazy
        alt="LiveSession App - Custom Events - Terms"
        title="Custom Events - terms"
      />
      <p>
        You can pass up to 50 properties with every event to better understand your visitors’
        behavior. Here’s an example of a code snippet:
      </p>
      <Code
        lang="javascript"
        style={{ marginTop: 32, marginBottom: 24 }}
      >{`__ls("track", "User Subscribed", {
  plan_str: "premium",
  seats_int: 1,
  total_float: 255.50,
  isPatron_bool: true
});`}</Code>
      <Information color="warning" style={{ margin: '32px 0' }}>
        Remember to update sample values. You have to trigger the function on every page view as we
        do not store custom properties’ values in any way.
      </Information>
      <p>
        Custom events and their properties will be available on the{' '}
        <Link href="/help/what-is-a-session/">session player’s</Link> timeline, on the right side of
        the screen:
      </p>

      <Image
        src={customevent4}
        lazy
        alt="LiveSession App - Custom Events - Session Replay"
        title="Custom Events - session replay"
      />
      <p>
        If you want to send custom events, please refer to our{' '}
        <DevelopersLink blank href="/javascript-api/methods/#track">
          technical documentation
        </DevelopersLink>
        .
      </p>
    </div>
  );
};

const related = [
  {
    link: '/help/what-is-a-session/',
    title: 'What is a session?',
    description: 'A session is a set of the user’s interactions with a website within a timeframe.',
  },
  {
    link: '/help/how-does-the-search-work/',
    title: 'How does the Search work?',
    description: 'See how Search does looks like and how it works.',
  },
  {
    link: '/help/how-does-the-timeline-work/',
    title: 'How does the Timeline work?',
    description: 'See how Timeline does looks like and how it works.',
  },
];

export const frontmatter = {
  metaTitle: 'How do the Custom Events work?',
  metaDescription: 'Wondering about the usability of our Custom Events? Read here.',
  canonical: '/help/how-do-the-custom-events-work/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
