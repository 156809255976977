import * as React from 'react';
import PropTypes from 'prop-types';

function hrefHelper(h) {
  return h.startsWith('/') ? h : `/${h}`;
}

export function Link({ children, blank, href }) {
  const additionalProps = {};

  if (blank) {
    additionalProps.target = '_blank';
  }

  return (
    <a href={href} {...additionalProps}>
      {children}
    </a>
  );
}

Link.propTypes = {
  blank: PropTypes.bool,
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Link.defaultProps = {
  blank: false,
};

export function DevelopersLink({ children, href, ...props }) {
  const additionalProps = {
    href: `${process.env.GATSBY_LS_DEVELOPERS_URL}${hrefHelper(href)}`,
  };

  return (
    <Link {...props} {...additionalProps}>
      {children}
    </Link>
  );
}

DevelopersLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
